import React from "react";
import { Grid, Hidden} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
  },
  borderGrid: {
    [theme.breakpoints.down("xs")]: {
      border: "none",
    },
    border: "solid 1pt #E9E9E9",
    borderTop: 'none',
  },
}));

export default function Tester(props)  {
    const classes = useStyles();
    const [value, setValue] = React.useState('recents');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
return (
  <React.Fragment>
    <Grid container alignContent="center" className="flex-section">
    <Hidden xsDown>
      <Grid
        item
        style={{padding: '32px 32px 0px 0px'}}
        className={"flex-col-scroll"}
      >
          {props.nav}
      </Grid>
      </Hidden>
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        xl={3}
        style={{width: '100%'}}
        className={`flex-col-scroll ${classes.borderGrid}`}
      >
          {props.main}
      </Grid>
      <Hidden smDown>
      <Grid
        item
        xs={4}
        sm={5}
        md={4}
        xl={2}
        style={{padding: '16px 16px 0px 32px'}}
        className={"flex-col-scroll"}
      >
          {props.extra}
      </Grid>
      </Hidden>
    </Grid>
   
    
  </React.Fragment>
)}
