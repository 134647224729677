import React, { useEffect } from "react";
import { makeStyles, fade } from "@material-ui/core/styles";
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Tester from './Tester'


import {
  Dialog,
  Typography,
  List,
  IconButton,
  Grid,
  Button,
  Divider,
  Input
} from "@material-ui/core";

import logo from './favicon.png'

import en from 'javascript-time-ago/locale/en'
import ru from 'javascript-time-ago/locale/ru'


import Toolbar from '@material-ui/core/Toolbar';

import { Twemoji } from 'react-emoji-render';

import SvgIcon from '@material-ui/core/SvgIcon';

import ListItem from "@material-ui/core/ListItem";

import ListItemText from "@material-ui/core/ListItemText";


import About from "../components/About";
import Hidden from "@material-ui/core/Hidden";

import {
  fieldValue,
  myFirebase,
  twitterProvider,
  googleProvider,
  db,
  Timestamp
} from "../firebase/firebase";
import CircularProgress from '@material-ui/core/CircularProgress';


import { useHistory } from "react-router-dom";

import TimeAgo from 'javascript-time-ago'

import ReactTimeAgo from 'react-time-ago'

import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";

import { useTheme } from "@material-ui/core/styles";


import { Virtuoso } from "react-virtuoso";
import { deepOrange } from "@material-ui/core/colors";

import ME from './ME_AGAIN.png'



import MenuItem from '@material-ui/core/MenuItem';

import Menu from '@material-ui/core/Menu';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';

import  {Link}  from "react-router-dom";

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)

const seo = (title, desc) => {
  title = title || "Fiddy | A weekly leaderboard of the best new side projects";
  desc = desc || 'Discover the best new Indie Hacker side projects in fifty characters or less, or submit your own. Like, comment on, and share the projects you find.';

  document.title = title;
  document.querySelector('meta[name="description"]').setAttribute('content', desc);
}

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
      <div style={{flexGrow: 1}}>
      {onClose ? (
        <IconButton style={{margin: 0, padding: 8, color: 'black',  marginLeft: 16} }aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
      </div>
  );
});

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const StyledMenu = withStyles({
  paper: {
  
  },
})(props => (
  <Menu
    elevation={2}
    MenuListProps={{ disablePadding: true }}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
));

const StyledMenuTop = withStyles({
  paper: {
  
  },
})(props => (
  <Menu
    elevation={2}
    MenuListProps={{ disablePadding: true }}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    margin: 0,

    fontFamily: 'Open Sans',
    '&:focus': {
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: 'black',
      },
    },
  },
}))(MenuItem);

const signInUserTwitter = () => {
  myFirebase.auth().signInWithRedirect(twitterProvider);
};

const signInUserGoogle = () => {
  myFirebase.auth().signInWithRedirect(googleProvider);
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: "white",
  },
  drawer: {
    width: '100px',
    flexShrink: 0,
  },
  drawerPaper: {
    width: '100px',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  btn: {
    backgroundColor: "#e9e9e9",
    color: "black",
    "&&:hover": {
      backgroundColor: "#ffc700",
      color: "black",
    },
  },
  subBtn: {
    backgroundColor: "#ffc700",
    color: "black",
    "&&:hover": {
      backgroundColor: "#f7c600",
      color: "black",
    },
  },
  gridList: {
    width: "80%",
    height: "100vh",
  },
  link: {
    color: "grey",
    "&&:hover": {
      textDecoration: "none",
      textDecorationColor: "transparent",
    },
  },
  hoverLight: {
    "&&:hover": {
      backgroundColor: "#fbfbfb",
    },
  },
  input: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
    fontFamily: "Open Sans",
    fontSize: 18,
  },
  nav: {
    backgroundColor: "#fafafa",
    "&&:disabled": {
      backgroundColor: "#fafafa",
    },
    "&&:hover": {
      backgroundColor: "#dfdfdf",
    },
  },
  noFocus: {
    "&&:hover": {
      backgroundColor: "#00000000",
      color: "orange",
    },
  },
  addButton: {
    borderRadius: 100,
    padding: "6px 12px 6px 12px",
    backgroundColor: "none",
    textTransform: "none",
    fontWeight: 800,
    fontFamily: "Open Sans",
    marginRight: 8,
    "&&:hover": {
      backgroundColor: "#fafafa",
    },
  },
  borderGrid: {
    [theme.breakpoints.down("xs")]: {
      border: "none",
    },
    border: "solid 1pt #E9E9E9",
    borderTop: 'none'
  },
  filterButton: {
    textTransform: "none",
    marginRight: 8,
    fontFamily: "Open Sans",
    padding: "6px 12px 6px 12px",
    fontWeight: 900,
    borderRadius: 100,
    color: "black",
    backgroundColor: "transparent",
    "&&:hover": {
      color: "black",
      backgroundColor: "#fafafa",
    },
  },
  signInButtonTwitter: {
    textTransform: "none",
    backgroundColor: "#1DA1F2",
    color: "white",
    fontWeight: 900,
    fontFamily: "Open Sans",
    borderRadius: 100,
    marginBottom: 16,
    padding: "4px 16px",
    "&&:hover": {
      color: "white",
      backgroundColor: "#188fd9",
    },
  },
  signInButtonGoogle: {
    textTransform: "none",
    backgroundColor: "#f6f6f6",
    color: "black",
    fontWeight: 900,
    fontFamily: "Open Sans",
    borderRadius: 100,
    padding: "4px 16px",
    "&&:hover": {
      color: "black",
      backgroundColor: "#f3f3f3",
    },
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  input : {
    backgroundColor: '#fafafa',
    "&&:hover": {
      backgroundColor: "#f7f7f7",
    },
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  }
}));

export default function Home(props) {
  const classes = useStyles();

  const [user, setUser] = React.useState(null);
  const [isLoggedIn, setLoggedIn] = React.useState(null);
  const [loadedProjects, setLoadedProjects] = React.useState([]);
  const [tempProjects, setTempProjects] = React.useState([]);
  const [filter, setFilter] = React.useState('week');

  const [currentProject, setCurrentProject] = React.useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [anchorElTop, setAnchorElTop] = React.useState(null);

  const [anchorElLoginEvent, setAnchorElLoginEvent] = React.useState(null);

  const [open, setOpen] = React.useState(false);

  const [showComments, setShowComments] = React.useState([]);

  const [loading, setLoading] = React.useState(false)

  const [reason, setReason] = React.useState('Login to Fiddy')

  let commentInput = React.useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCloseComment = () => {
    setOpen(false);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickTop = event => {
    setAnchorElTop(event.currentTarget);
  };

  const handleCloseTop = () => {
    setAnchorElTop(null);
  };

  const handleClickLoginEvent = event => {
    setReason("Login to Fiddy")
    setAnchorElLoginEvent(event.currentTarget);
  };

  const handleCloseLoginEvent = () => {
    setAnchorElLoginEvent(null);
  };

  const theme = useTheme();

  const treeify = (list, idAttr, parentAttr, childrenAttr, rootCheck) => {
    if (!idAttr) idAttr = 'id';
    if (!parentAttr) parentAttr = 'reply_to';
    if (!childrenAttr) childrenAttr = 'children';

    var treeList = [];
    var lookup = {};
    list.forEach(function(obj) {
        lookup[obj[idAttr]] = obj;
        obj[childrenAttr] = [];
    });
    list.forEach(function(obj) {
        if (obj[parentAttr] !== rootCheck) {
            lookup[obj[parentAttr]][childrenAttr].push(obj);
        } else {
            treeList.push(obj);
        }
    });
    return treeList;
};

//FIGURE OUT A WAY TO PREVENT FRAUDULENT CLICK / OR TO MANY
// const clickProject = (parent) => {

//   let clickContent =  {
//     parent: parent,
//   } 

//   db.collection('clicks')
//   .doc()
//   .set(clickContent).then(result => {
//   })

// }

  const comment = (parent, content, att) => {

    let commentContent =  {
      content: content,
      parent: parent,
      datetime: Timestamp.fromDate(new Date()),
      user_id: user['uid'],
      photoURL: user['providerData'][0]['photoURL'].replace('normal', '400x400'),
      username: user['displayName'],
      reply_to: att['id'],
      reply_to_username: att['username'] != undefined ? att['username'] : "",
      depth: att['depth'] == undefined ? 0 : att['depth'] + 1
    } 

    db.collection('comments')
    .doc()
    .set(commentContent).then(result => {
      getProjectComments(currentProject, true)
    })

  }




  const [commentParent, setCommentParent] = React.useState("")

  const commentDialog = 
    <Dialog fullWidth={true} maxWidth={'xs'} height={200} onClose={handleCloseComment} aria-labelledby="customized-dialog-title" open={open}>
    <Toolbar disableGutters={true}>
    <DialogTitle id="customized-dialog-title" onClose={handleCloseComment}/>
     <Button onClick={() => {
       if( commentInput.current.value != "" && commentInput.current.value != null) {
        comment(currentProject['id'], commentInput.current.value, commentParent)
        handleCloseComment()
       }
     }} className={classes.btn} style={{
                  fontSize: 16,
                  fontWeight: 900,
                  borderRadius: 100,
                  textTransform: "none",
                  fontFamily: "Open Sans",
                  padding: "6px 12px",
                  color: 'black',
                  marginRight: 16,
                }} autoFocus color="primary">
        Comment
      </Button>
    </Toolbar>
  
    <Divider/>

    {commentParent['username'] != undefined ? 
    <ListItem divider style={{padding: "16px 16px 16px 0px", paddingLeft: 16}}  alignItems="flex-start">
    

    
      <ListItemAvatar>
      <Avatar
          alt={commentParent['displayName']}
          style={{
            marginRight: 16,
            backgroundColor: "#ffc700",
            width: 40,
            height: 40
          }}
          src={
            commentParent['photoURL']
          }
        /> 
      </ListItemAvatar>
      
      <ListItemText 
      primary={
      <div style={{fontWeight: 900, fontFamily: 'Open Sans', fontSize: 16}}>
        {commentParent['username']} 
        <span style={{fontWeight: 400, color: '#aaaaaa', fontSize: 14, fontFamily: 'Open Sans'}}> · <ReactTimeAgo date={commentParent['datetime'] == undefined ? "" : commentParent['datetime'].toDate()} timeStyle="twitter"/> </span>
      </div>} 
      secondary={
        <>
      <div>
        
        <Twemoji style={{fontFamily: 'Open Sans', fontSize: 16}} svg text={commentParent['content']}/>
      </div>
      
      </>
    }/>
        
    </ListItem>  : "" }

    <Input
          autoFocus={true}
          fullWidth
          rowsMax={15}
          rows={15}
          height={500}
          maxLength="10"
          inputRef={commentInput}
          inputProps={{maxLength: 240}}
          id="standard-textarea"
          placeholder={commentParent['username'] != undefined ? "Reply to " + commentParent['username'] : 'Say someting nice or constructive about ' + commentParent['name']}
          className={classes.input}
          style={{maxLength: 100, backgroundColor: 'white', padding: 16, fontFamily: 'Open Sans', fontSize: 16}}
          multiline
        />
    <DialogActions>
     
    </DialogActions>
  </Dialog>

 

  const getThisWeek = (filter, tag) => {
    setLoading(true)
    db.collection("tmp_projects")
      .orderBy("votes", "desc")
      .get()
      .then((snap) => {
        let projects = [];
        snap.docs.forEach((proj) => {
            let project = proj.data();
            if(project['desc'].includes(filter)){
              project["id"] = proj.id;
              if(!project['featured'] && !project['winner']) {
                projects.push(project);
              }
            }
        });
        featureProjects.forEach(ftp => {
          projects.unshift(ftp)
        })
        setLoadedProjects(projects);
        setLoading(false)
      });
    setFilter(tag);
    setAnchorEl(null)
  }

  const flatmap = (tree, arr) => {
    return tree.forEach((e) => {
      arr.push(e)
      if(e['children'].length == 0) {
        return
      } else {
        e['nr_comments'] = e['children'].length
      }
      return flatmap(e['children'], arr)
    })
  }

const getProjectComments = (project, setProject) => {
  db.collection("comments")
  .orderBy("datetime", "desc")
  .where('parent', '==', project['id'])
  .get()
  .then((snap) => {
    project['comments'] = snap.docs.map(doc => {
      let comment = doc.data()
      comment["id"] = doc.id;
      return comment
    })
    let commentTree = treeify(project['comments'], null, null, null, project['id'])
    let arr = []
    flatmap(commentTree, arr)
    project['comments'] = arr
    if(setProject) {
      setCurrentProject({...project, comments: project['comments']})
    } else {
      return project
    }
   
    
  });
}

  const filterThisWeek = (e, isSearching) => {
    if (filter !== 'week') {
      setLoadedProjects([]);
      db.collection("tmp_projects")
        .orderBy("votes", "desc")
        .get()
        .then((snap) => {
          let projects = [];
          snap.docs.forEach((proj) => {
            let project = proj.data();
            project["id"] = proj.id;
            if(!project['featured'] && !project['winner']) {
              projects.push(project);
            }
          });
          featureProjects.forEach(ftp => {
            projects.unshift(ftp)
          })
          setLoadedProjects(projects);
        });
      if(isSearching) {
        setFilter('search');
      } else {
        setFilter('week');
      }
    }
    setAnchorElTop(null)
  };

  const filterAllTime = (e) => {
    setLoading(true)
    if (filter !== 'all') {
      db.collection("tmp_projects")
        .orderBy("score", "desc")
        .get()
        .then((snap) => {
          let projects = [];
          snap.docs.forEach((proj) => {
            let project = proj.data();
            project["id"] = proj.id;
            if(!project['featured'] && !project['winner']) {
              projects.push(project);
            }
          });
          featureProjects.forEach(ftp => {
            projects.unshift(ftp)
          })
          setLoadedProjects(projects);
          setLoading(false)
        });
      setFilter('all');

    }
    setAnchorElTop(null)
  };

  const filterStarred = (e) => {
    getThisWeek('⭐','star')
  };

  const filterTop = (e) => {
    getThisWeek('⚡','top')
  };


  const filterPOW = (e) => {
    getThisWeek('🏆','pow')
  };

  const filterMenu = 
  <>
  <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{fontFamily: 'Open Sans'}}
      >
        <Hidden mdUp>
        <Typography style={{fontFamily: 'Open Sans',margin: '12px 18px 12px 12px',fontWeight: 600, color: 'black', fontSize: 18}}>
            Awards
        </Typography>
        <Divider/>
        </Hidden>
        <StyledMenuItem style={filter === 'star' ? {backgroundColor: "#f7f7f7"} : {}}  onClick={filterStarred}>
          <ListItemIcon>
          <Twemoji svg text="⭐" />
          </ListItemIcon>
          <ListItemText primary={<b style={{fontFamily: 'Open Sans'}}>Recommended</b>} secondary={<span style={{fontFamily: 'Open Sans'}}>Awesome projects I<br/>recommend</span>} />
        </StyledMenuItem>
        <StyledMenuItem style={filter === 'top' ? {backgroundColor: "#f7f7f7"} : {}}  onClick={filterTop}>
          <ListItemIcon>
          <Twemoji svg text="👏" />
          </ListItemIcon>
          <ListItemText primary={<b style={{fontFamily: 'Open Sans'}}>Best description</b>} secondary={<span style={{fontFamily: 'Open Sans'}}>Best description in<br/>the fewest characters</span>} />
        </StyledMenuItem>
        <StyledMenuItem style={filter === 'pow' ? {backgroundColor: "#f7f7f7"} : {}}  onClick={filterPOW}>
          <ListItemIcon>
          <Twemoji svg text="🏆" />
          </ListItemIcon>
          <ListItemText primary={<b style={{fontFamily: 'Open Sans'}}>Project of the week</b>} secondary={<span style={{fontFamily: 'Open Sans'}}>Every week, I choose<br/>my favorite project</span>} />
        </StyledMenuItem>
      </StyledMenu>
  </>


  const loginMenu = <>

<StyledMenu
        id="customized-menu"
        anchorEl={anchorElLoginEvent}
        keepMounted
        open={Boolean(anchorElLoginEvent)}
        onClose={handleCloseLoginEvent}
        style={{fontFamily: 'Open Sans'}}
      >
        <Hidden mdUp>
        <StyledMenuItem style={{padding: "16px 16px 16px 16px", fontWeight: 600,}} onClick={() => {
          setAnchorElLoginEvent(null)
          setReason('Login to Fiddy')
          history.push('/about')
        }}>
          About
        </StyledMenuItem>
        <Divider/>
        </Hidden>

        
        {!isLoggedIn ?
        <>
        <Typography style={{margin: '16px 18px 0px 16px',fontWeight: 600, color: 'black', fontSize: 16, fontFamliy: 'Open Sans'}}>
           {reason}
        </Typography>
        <Typography style={{margin: '0px 18px 4px 16px',fontWeight: 400, color: '#aaaaaa', fontSize: 14, fontFamliy: 'Open Sans'}}>
          Join Fiddy with Twitter or Google
       </Typography>
        </>
       : "" }
        
   
        
        {!isLoggedIn ? <>
        <Button onClick={()=> {signInUserTwitter()}} style={{textTransform: 'none', color: '#aaaaaa', backgroundColor: '#f9f9f9', fontWeight: 400, borderRadius: 4, height: 45, width: 215, margin: 16}}>
        <i style={{color: '#0aa9f4', fontSize: 20, marginRight: 14}}class="ri-twitter-fill"></i>Continue with Twitter
        </Button>
        <div/>
        <Button  onClick={()=> {signInUserGoogle()}} style={{textTransform: 'none', color: '#aaaaaa', backgroundColor: '#f9f9f9', fontWeight: 400, borderRadius: 4, height: 45, width: 215, margin: 16, marginTop: 0}}>
        <svg style={{marginRight: 16}} width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M17.64 9.205c0-.639-.057-1.252-.164-1.841H9v3.481h4.844a4.14 4.14 0 0 1-1.796 2.716v2.259h2.908c1.702-1.567 2.684-3.875 2.684-6.615z" fill="#4285F4" fill-rule="nonzero"></path><path d="M9 18c2.43 0 4.467-.806 5.956-2.18l-2.908-2.259c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711H.957v2.332A8.997 8.997 0 0 0 9 18z" fill="#34A853" fill-rule="nonzero"></path><path d="M3.964 10.71A5.41 5.41 0 0 1 3.682 9c0-.593.102-1.17.282-1.71V4.958H.957A8.996 8.996 0 0 0 0 9c0 1.452.348 2.827.957 4.042l3.007-2.332z" fill="#FBBC05" fill-rule="nonzero"></path><path d="M9 3.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C13.463.891 11.426 0 9 0A8.997 8.997 0 0 0 .957 4.958L3.964 7.29C4.672 5.163 6.656 3.58 9 3.58z" fill="#EA4335" fill-rule="nonzero"></path><path d="M0 0h18v18H0z"></path></g></svg>Continue with Google
        </Button>
        </>
        : 
        
        <StyledMenuItem style={{padding: "16px 16px 16px 16px", fontWeight: 600,}} onClick={()=> {
          myFirebase.auth().signOut()
          setLoggedIn(false)
         
          }}>
          Log out
        </StyledMenuItem>
        }
      </StyledMenu>

  </>
  

  const trendingTopFilterMenu = <>

<StyledMenuTop
        id="customized-menu"
        anchorEl={anchorElTop}
        keepMounted
        open={Boolean(anchorElTop)}
        onClose={handleCloseTop}
        style={{fontFamily: 'Open Sans'}}
      >
        <Hidden mdUp>
        <Typography style={{fontFamily: 'Open Sans', margin: '12px 18px 12px 12px',fontWeight: 600, color: 'black', fontSize: 18}}>
            Projects
        </Typography>
        <Divider/>
        </Hidden>
        <StyledMenuItem style={filter === 'week' ? {backgroundColor: "#f7f7f7"} : {}} onClick={(e) => {
          filterThisWeek(e, false)
          }}>
          <ListItemIcon>
          <i style={{fontSize: 24, color: 'grey'}} class="ri-fire-line"></i>
          </ListItemIcon>
          <ListItemText primary={<b style={{fontFamily: 'Open Sans'}}>Trending</b>} secondary={<span style={{fontFamily: 'Open Sans'}}>See the hot projects with<br/>the most likes this week</span>} />
        </StyledMenuItem>
        <StyledMenuItem style={filter === 'all' ? {backgroundColor: "#f7f7f7"} : {}}  onClick={filterAllTime}>
          <ListItemIcon>
          <i style={{fontSize: 24, color: 'grey'}}  class="ri-medal-line"></i>
          </ListItemIcon>
          <ListItemText primary={<b style={{fontFamily: 'Open Sans'}}>Top</b>} secondary={<span style={{fontFamily: 'Open Sans'}}>The all time best projects<br/>on Fiddy</span>} />
        </StyledMenuItem>
      </StyledMenuTop>

  </>

  function UpvoteIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M35.644 4.11713C38.5049 -1.37238 47.4951 -1.37237 50.356 4.11714L85.2744 71.1175C87.6891 75.7507 83.7817 81 77.9184 81H8.08159C2.21828 81 -1.68906 75.7507 0.725577 71.1175L35.644 4.11713Z" fill={props.color} />
      </SvgIcon>
    )
  }

  const aboutSection = () => {
    return (
      <> 

       { props.match.params.quote === 'about' ? 
        <List>
          <IconButton onClick={() => {
            history.push('/')
            setFilter('week')
          }} style={{padding: 8}}>
            <i style={{color: 'black', fontSize: 24}}  class="ri-arrow-left-line"></i>
          </IconButton>
        </List>
        : ""}
       
         <div
          style={{ backgroundColor: "#fafafa", borderRadius: 4, marginTop: 8}}
        >
            
          <div style={{padding: 16, marginBottom: 12}}>

          <ListItem
          style={{ marginBottom: 16}}
        disableGutters
      >
         <a
            style={{ marginLeft: 0, textDecoration: 'none'}}
            className={classes.link}
            target="_blank"
            rel="noopener"
            href={"http://twitter.com/dijkstradev"}
          >
        <ListItemAvatar>
            <Avatar
              style={{
                marginRight: 16,
                border: "solid 3pt #eeeeee",
                backgroundColor: "#ffc700",
                width: 60,
                height: 60,
               

              }}
              src={
               ME
              }
            />
        </ListItemAvatar>
        </a>

        <ListItemText
          primary={
            <>
            <span style={{fontFamily: 'Open Sans', color: 'black', padding: '4px 8px', backgroundColor:  "#ffc700", fontWeight: 900, borderRadius: 2}} >Hey, I'm Hessel </span> 
            </>
          }
          />
          </ListItem>
         
          <Typography
                component="div"
                style={{
                  fontSize: 16,
                  color: "grey",
                  marginBottom: 0,
                  fontFamily: 'Open Sans',
                 
                }}
              >
                Every Friday I send out a newsletter with the best new projects on Fiddy. 
                It's simple and to the point. I'd love you to join the <b>3M</b> (nah, kidding there is about 250) current subscribers. <br/>
              </Typography>
              </div>
              <div style={{padding: 16, paddingTop: 0}}>
              <Button
                fullWidth
                onClick={() => {
                  window.open('https://fiddyweekly.substack.com/')
                }}
                className={classes.subBtn}
                style={{
                  fontSize: 16,
                  fontWeight: 800,
                  borderRadius: 4,
                  textTransform: "none",
                  fontFamily: "Open Sans",
                  padding: "10px 16px",
                  color: 'black',
                
                
               
                }}
               
              >
               Subscribe
              </Button>
              </div>
       </div>

      <div style={{marginTop: 8}}>
       <a
                style={{ color: "grey", fontFamily: 'Open Sans', textDecoration: 'none'}}
                target="_blank"
                rel="noopener"
                href="https://twitter.com/dijkstradev"
              >
                <div style={{borderRadius: 4, backgroundColor: '#fafafa', padding: "4px", display: 'inline-block'}}>
                Made with <Twemoji style={{marginRight: 1}} svg text="❤️" /> by
            
                <b>  @dijkstradev</b>
             
              </div>
              
              </a>
              </div>

        <div
          style={{ backgroundColor: "#fafafa", borderRadius: 4, marginTop: 8 }}
        >
          <Typography
            style={{
              borderRadius: 8,
              padding: "4px 8px 4px 8px",
              backgroundColor: "#fafafa",
              display: "inline-block",
              color: "black",
              marginLeft: 8,
              marginBottom: 8,
              fontSize: 18,
              fontFamily: "Open Sans",
              fontWeight: 900,
              marginTop: 8
            }}
          >
            About Fiddy
          </Typography>
          <Divider />
          <Divider />
          <div style={{ padding: 8 }}>
            <div>
              <Typography
                component="div"
                style={{
                  fontSize: 16,
                  color: "grey",
                  fontWeight: 400,
                  padding: 16,
                  fontFamily: 'Open Sans'
                }}
              >
              Fiddy is a weekly leaderboard of the best new Indie Hacker side projects           
              </Typography>
            </div>
            <About />
          </div>
        </div>
      
       
        <div
          style={{
            marginTop: 16,
            marginBottom: 16,
            backgroundColor: "rgba(255, 199, 0, 0.01)",
          }}
        >
          <Typography
            component="div"
            style={{
              fontWeight: 600,
              color: "grey",
              fontFamily: "Open Sans",
              fontSize: 14,
            }}
          >
            
            Inspired by a post on{" "}
            <a
              style={{ paddingTop: 16, color: "grey",fontFamily: 'Open Sans' }}
              target="_blank"
              rel="noopener"
              href="https://www.indiehackers.com/post/describe-your-company-in-50-characters-or-less-66f272149f"
            >
              <b>Indie Hackers </b>
            </a>
            <span></span>
            <div />
            <span style={{fontFamily: 'Open Sans'}}>
            View the open{" "}
            </span>
            <a
              style={{ paddingTop: 16, color: "grey", fontFamily: 'Open Sans'}}
              target="_blank"
              rel="noopener"
              href="https://plausible.io/fifty.weekendprojects.xyz?period=7d"
            >
              <b>analytics  </b>
            </a>
            <div/>
            <span style={{fontFamily: 'Open Sans'}}>
            For support contact me at {" "}
            </span>
            <a
              style={{ paddingTop: 16, color: "grey", fontFamily: 'Open Sans'}}
              target="_blank"
              rel="noopener"
              href="mailto:hessel@fiddy.co"
            >
              <b> hessel@fiddy.co </b>
            </a>
            <div/>
            <a
              style={{ paddingTop: 16, color: "grey", fontFamily: 'Open Sans'}}
              target="_blank"
              rel="noopener"
              href="https://dashboard.flutterwave.com/donate/xfyz1wvfofdd"
            >
              <b> Donate</b>
            </a>
            <span style={{fontFamily: 'Open Sans'}}>
            {" "} to help keep the server lights on
            </span>
           
           
          </Typography>
        </div>
        {/* <a href="https://www.producthunt.com/posts/fifty-2?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-fifty-2" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=205269&theme=dark&period=daily" alt="Fifty - Indie Hacker projects described in 50 characters | Product Hunt Embed"  width="250px" height="54px" /></a>   */}
        <div style={{ marginBottom: 32 }} />
        <div />
      </>
    );
  };

  const history = useHistory();

  const [featureProjects, setFeatureProjects] = React.useState(null);

  const [anchorElLogin, setAnchorElLogin] = React.useState(null);
  const openLogin = Boolean(anchorElLogin);

  const [align, setAlign] = React.useState('start')
  const virtuosoMain = React.useRef(null)

  useEffect(() => {
    if(filter == 'search') {
      searchInput.current.focus()
    }
  }, [filter])

  useEffect(() => {
    seo()
    if (props.match.params.quote != undefined) {
      if(loadedProjects.length === 0 ) { 
        db.collection("tmp_projects")
        .doc(props.match.params.quote)
        .get()
        .then((doc) => {
          if(doc.data() != undefined) {
            let proj = doc.data()
            proj['id'] = doc.id
            setCurrentProject(proj)
            getProjectComments(proj, true)
            setShowComments([proj['id']])
            seo(proj['name'], proj['desc'])
          } else {
            history.push('/')
          }
        })
      } else {
        let projects = loadedProjects
        projects.forEach((proj, i) => {
          if (proj["link"].includes(props.match.params.quote) || proj['id'] === props.match.params.quote) {
            setCurrentProject(proj)
            getProjectComments(proj, true)
            setShowComments([proj['id']])
            seo(proj['name'], proj['desc'])
          }
        });
      }
     
    } else {
      if(loadedProjects.length === 0 ) {
        db.collection("tmp_projects")
      .orderBy("votes", "desc")
      .get()
      .then((snap) => {
        let projects = [];
        var ftps = [];
        snap.docs.forEach((proj) => {
          let project = proj.data();
          project["id"] = proj.id;
          if(project['featured'] || project['winner']) {
            ftps.push(project);
          } else {
            projects.push(project);
          }
          //getProjectComments(project, false)
        });
        if(ftps.length != 0) {
          ftps.forEach(ftp => {
            projects.unshift(ftp)
          })
          setFeatureProjects(ftps)
        }
        setLoadedProjects(projects);
      });
      } 
      setCurrentProject(null)
    }
  }, [props.match.params.quote])

  let searchInput = React.useRef(null);

  const [searchList, setSearchList] = React.useState([])
  const [searchTerm, setSearchTerm] = React.useState([])
  const [searching, setSearching] = React.useState(false)
  const [busySearching, setBusySearching] = React.useState(false)


  const handleSearch = (e) => {
    let searchTerm = e.target.value
    setBusySearching(true)
    if(searchTerm === "") {
      setTimeout(() =>{
      setSearching(false)
      setSearchList([])
     
    }, 600)
    } else {
      setTimeout(() =>{
        setSearchList(loadedProjects.filter((p) => {
        let projectStrings = p['name'] + " " + p['desc'] + " " + p['twitter'] + " " +  p['link']
        return projectStrings.toLowerCase().includes(searchTerm.toLowerCase())
      }))
      setSearching(true)}, 600)
    }
    setTimeout(() => {
      setBusySearching(false)
    }, 700)
  }
  
  useEffect(() => {
    db.collection("tmp_projects")
      .orderBy("votes", "desc")
      .get()
      .then((snap) => {
        let projects = [];
        var ftps =[];
        snap.docs.forEach((proj) => {
          let project = proj.data();
          project["id"] = proj.id;
          if(project['featured'] || project['winner']) {
            ftps.push(project);
          } else {
            projects.push(project);
          }
        });
        if(ftps.length != 0) {
          ftps.forEach(ftp => {
            projects.unshift(ftp)
          })
          setFeatureProjects(ftps)
        }
        setLoadedProjects(projects);
      });

    myFirebase
      .auth()
      .getRedirectResult()
      .then((result) => {
        setUser(result.user);
        setLoggedIn(true);
      })
      .catch((error) => {
        console.log(error);
      });

    myFirebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        setLoggedIn(true);
      } else {
        setUser(null);
        setLoggedIn(false);
      }
    });
  }, []);

  const upvoteProject = (projectId) => {

    db.collection("tmp_projects")
      .doc(projectId.toString())
      .update({
        votes: fieldValue.increment(1),
        voters: fieldValue.arrayUnion(user.uid),
      })

      if(currentProject != null) {
        let temp = currentProject
        temp["votes"] = temp["votes"] + 1;
        temp["voters"].push(user.uid);
        setCurrentProject(temp)
      }
     
      let tProjects = [...loadedProjects];
      let proj = loadedProjects.find((p) => p.id === projectId);
      proj["votes"] = proj["votes"] + 1;
      proj["voters"].push(user.uid);
      tProjects[loadedProjects.map((e) => e.id).indexOf(projectId)] = proj;
      setLoadedProjects(tProjects);
  
  };

  const unvoteProject = (projectId) => {
    db.collection("tmp_projects")
      .doc(projectId.toString())
      .update({
        votes: fieldValue.increment(-1),
        voters: fieldValue.arrayRemove(user.uid),
      })

      if(currentProject != null) {
        let temp = currentProject
        temp["votes"] = temp["votes"] - 1;
        temp["voters"].splice(temp["voters"].indexOf(user.uid), 1);
        setCurrentProject(temp)
      }
   
      let tProjects = [...loadedProjects];
      let proj = loadedProjects.find((p) => p.id === projectId);
      proj["votes"] = proj["votes"] - 1;
      proj["voters"].splice(proj["voters"].indexOf(user.uid), 1);
      tProjects[loadedProjects.map((e) => e.id).indexOf(projectId)] = proj;
      setLoadedProjects(tProjects);
    
  };

  const handleClosePopoverLogin = () => {
    setAnchorElLogin(null);
  };

  const GenProjectItem = (index) => {
    let project = index === -1 ? currentProject 
    : 
     !searching ? 
    loadedProjects[index] : searchList[index]
    let single = index === -1
    return (
      
      <ListItem
        divider
        style={{ padding: '8px 16px 0px 16px' }}
        className={classes.hoverLight}
        disableGutters
        alignItems="flex-start"
      >
         <a
            style={{ marginLeft: 0, textDecoration: 'none'}}
            className={classes.link}
            target="_blank"
            rel="noopener"
            href={"http://twitter.com/" + project["twitter"]}
          >
        <ListItemAvatar>
            <Avatar
              alt={project["name"]}
              style={{
                marginRight: 16,
                border: "solid 3pt #f7f7f7",
                backgroundColor: "#fafafa",
                width: single ? 70: 60,
                height: single ? 70: 60,
                color: "grey"
              }}
              src={
                project['logo'] === undefined ? 
                "http://logo.clearbit.com/" + 'https://' + project['link'].replace('http://','').replace('https://','').split(/[/?#]/)[0] : project['logo']
              }
            />
        </ListItemAvatar>
        </a>

        <ListItemText
          primary={
            <>
              <Link to={'/' + project['id']} style={{textDecoration: 'none'}}>
              <Grid
                container
                direction="row"
                alignItems="center"
              >
       
               
{/*              

              {project["desc"].includes("⭐") ? (
                <Grid item
                  style={{
                    marginLeft: 0,
                    marginRight: 8,
                    marginBottom: 8,
                    marginTop: 8,
                    fontSize: 14,
                    padding: "6px 8px 4px 8px",
                    borderRadius: 100,
                    backgroundColor: "#f1f1f1",
                  }}
                >
                  <Twemoji svg text="⭐" />
                </Grid>
              ) : null}

              {project["desc"].includes("⚡") ? (
                <Grid item
                  style={{
                    marginRight: 8,
                    fontSize: 14,
                    marginBottom: 8,
                    marginTop: 8,
                    padding: "6px 8px 4px 8px",
                    borderRadius: 100,
                    backgroundColor: "#f1f1f1",
                  }}
                >
                  <Twemoji svg text="👏" />
                </Grid>
              ) : null}

              {project["desc"].includes("🏆") ? (
                <Grid item
                  style={{
                    marginRight: 8,
                    fontSize: 14,
                    marginBottom: 8,
                    marginTop: 8,
                    padding: "6px 8px 4px 8px",
                    borderRadius: 100,
                    backgroundColor: "#f1f1f1",
                  }}
                >
                  <Twemoji svg text="🏆" />
                </Grid>
              ) : null} */}
               </Grid>
           
        
              <div>
                <Typography
                  component={"span"}
                  style={{
                    fontWeight: 900,
                    marginRight: 6,
                    wordBreak: "break-word",
                    color: "black",
                    fontFamily: "Open Sans",
                    fontSize: single ? 18 : 16,
                    marginTop: 8,
                  }}
                >
                  {project["name"]}
                </Typography>

                <Typography
                  component="span"
                  style={{
                    color: "#aaaaaa",
                    marginTop: 4,
                    fontWeight: 400,
                    fontFamily: "Open Sans",
                    fontSize: single ? 16 : 14,
                  }}
                >
                  ·{" "}
                  <ReactTimeAgo date={project['datetime'].toDate()} timeStyle="twitter"/> 
                </Typography>

                {(project['featured'] || project['winner'])  ?
                <Typography
                  component="span"
                  style={{
                    color: "#aaaaaa",
                    marginTop: 4,
                    marginLeft: 8,
                    fontWeight: 400,
                    fontFamily: "Open Sans",
                    fontSize: single ? 16 : 14,
                    borderRadius: 100,
                    padding: "4px 8px",
                    backgroundColor: '#f7f7f7'
                  }}
                >
                  Promoted
                </Typography>
                : ""}

                <Typography
                  style={{
                    marginTop: 0,
                    fontWeight: 200,
                    color: "black",
                    fontFamily: "Open Sans",
                    fontSize: single? 18: 18,
                  }}
                >
                  <span
                    style={{ wordBreak: "break-word"}}
                  >
                    {project["desc"]
                      .replace("🏆", "")
                      .replace("⚡", "")
                      .replace("⭐", "")
                      .replace(".", "")}
                  </span>
                </Typography>
            </div>
            </Link>
            <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
                style={{marginTop: 12, marginLeft: -8}}
              >
                

                <Grid item>
                  <IconButton onClick={() => {
                    history.push('/' + project['id'])}
                    } style={{padding: 8, marginBottom: 2, marginRight: 2}}>
                    <i style={{color: 'grey', fontSize: 20}} class="ri-chat-1-line"></i>
                  </IconButton>
                  <Typography variant="div" style={{color: 'grey', fontSize: 12, fontFamily: 'Open Sans'}}>
                    {project['comments'] != undefined ? (project['comments'].length == 0 ? <span style={{color: 'transparent'}}>0</span> : project['comments'].length) : project['nr_comments'] != undefined ? project['nr_comments'] == 0 ? <span style={{color: 'transparent'}}>0</span> : project['nr_comments']  :  <span style={{color: 'transparent'}}>0</span>}
                  </Typography>
                </Grid>

                <Grid item>
                  <IconButton onClick={(e) => {
                        if (isLoggedIn) {
                          if (project["voters"].includes(user.uid)) {
                            unvoteProject(project["id"]);
                          } else {
                            upvoteProject(project["id"]);
                          }
                        } else {
                          setAnchorElLoginEvent(e.target);
                          setReason("Login to like a project")
                        }
                      }} style={{padding: 8, marginBottom: 2, marginRight: 2}}>
                    <i style={{color: isLoggedIn
                          ? project["voters"].includes(user.uid)
                            ? "#e0245e"
                            : "grey"
                          : "grey", fontSize: 20}}  class={"ri-heart-3-" + (isLoggedIn ? project["voters"].includes(user.uid) ? "fill" : "line" : "line")}></i>
                  </IconButton>
                  <Typography variant="div" style={{color: 'grey', fontSize: 12, fontFamily: 'Open Sans'}}>
                    {project['votes'] + project['score'] == 0 ? " " : project['votes'] + project['score']}
                  </Typography>
                </Grid>

                <Grid item>
                  <IconButton onClick={() => window.open('https://' + project['link'])} style={{padding: 8, marginBottom: 2, marginRight: 2}}>
                    <i style={{color: 'grey', fontSize: 20}}  class="ri-link"></i>
                  </IconButton>
                </Grid>

                <Grid item>
                  <IconButton onClick={() => {
                    window.open('https://twitter.com/intent/tweet?url=' + 'Check out ' + project['name'] + ' on Fiddy fiddy.co/' + project['id'])
                  }} style={{padding: 8, marginBottom: 2, marginRight: 2}}>
                    <i style={{color: 'grey', fontSize: 20}}  class="ri-share-line"></i>
                  </IconButton>
                </Grid>
                

              </Grid>

            </>
          }
        />
      </ListItem>
    );
  };

  
  const GenCurrentProject = () => {
    let project = currentProject 
    return (
      
      <ListItem
        divider
        style={{ padding: '16px 32px 32px 32px' }}
        className={classes.hoverLight}
        disableGutters
        alignItems="flex-start"
      >
         <a
            style={{ marginLeft: 0, textDecoration: 'none'}}
            className={classes.link}
            target="_blank"
            rel="noopener"
            href={"http://twitter.com/" + project["twitter"]}
              >
            <ListItemAvatar>
                <Avatar
                  alt={project["name"]}
                  style={{
                    marginRight: 16,
                    border: "solid 3pt #f7f7f7",
                    backgroundColor: "#fafafa",
                    width: 70,
                    height: 70,
                    color: 'grey'
                  }}
                  src={
                    project['logo'] === undefined ? 
                    "http://logo.clearbit.com/"  + 'https://' + project['link'].replace('http://','').replace('https://','').split(/[/?#]/)[0] : project['logo']
                  }
                />
            </ListItemAvatar>
            </a>
         

        <ListItemText
          primary={
            <>
           
              <a 
               target="_blank"
              rel="noopener"
              href={'https://' + project['link']} style={{textDecoration: 'none'}}>
              <Grid
                container
                direction="row"
                alignItems="center"
              >
       
              {/* {project["desc"].includes("⭐") ? (
                <Grid item
                  style={{
                    marginLeft: 0,
                    marginRight: 8,
                    marginBottom: 8,
                    marginTop: 8,
                    fontSize: 14,
                    padding: "6px 8px 4px 8px",
                    borderRadius: 100,
                    backgroundColor: "#f1f1f1",
                  }}
                >
                  <Twemoji svg text="⭐" />
                </Grid>
              ) : null}

              {project["desc"].includes("⚡") ? (
                <Grid item
                  style={{
                    marginRight: 8,
                    fontSize: 14,
                    marginBottom: 8,
                    marginTop: 8,
                    padding: "6px 8px 4px 8px",
                    borderRadius: 100,
                    backgroundColor: "#f1f1f1",
                  }}
                >
                  <Twemoji svg text="👏" />
                </Grid>
              ) : null}

              {project["desc"].includes("🏆") ? (
                <Grid item
                  style={{
                    marginRight: 8,
                    fontSize: 14,
                    marginBottom: 8,
                    marginTop: 8,
                    padding: "6px 8px 4px 8px",
                    borderRadius: 100,
                    backgroundColor: "#f1f1f1",
                  }}
                >
                  <Twemoji svg text="🏆" />
                </Grid>
              ) : null} */}
               </Grid>
           
        
              <div>
                <Typography
                  component={"span"}
                  style={{
                    fontWeight: 900,
                    marginRight: 6,
                    wordBreak: "break-word",
                    color: "black",
                    fontFamily: "Open Sans",
                    fontSize: 16,
                    marginTop: 8,
                  }}
                >
                  {project["name"]}
                </Typography>

                <Typography
                  component="span"
                  style={{
                    color: "#aaaaaa",
                    marginTop: 4,
                    fontWeight: 400,
                    fontFamily: "Open Sans",
                    fontSize: 14
                  }}
                >
                  ·{" "}
                  <ReactTimeAgo date={project['datetime'].toDate()} timeStyle="twitter"/> 
                </Typography>

                {(project['featured'] || project['winner'])  ?
                <Typography
                  component="span"
                  style={{
                    color: "#aaaaaa",
                    marginTop: 4,
                    marginLeft: 8,
                    fontWeight: 400,
                    fontFamily: "Open Sans",
                    fontSize: 14,
                    borderRadius: 100,
                    padding: "4px 8px",
                    backgroundColor: '#f7f7f7'
                  }}
                >
                  Promoted
                </Typography>
                : ""}

                <IconButton style={{padding: 3, marginBottom: 2, marginLeft: 4}}>
                    <i style={{ fontSize: 18}}  class="ri-link"></i>
                  </IconButton>

                <Typography
                  style={{
                    marginTop: 4,
                    fontWeight: 200,
                    color: "black",
                    fontFamily: "Open Sans",
                    fontSize: 22
                  }}
                >
                  <span
                    style={{ wordBreak: "break-word"}}
                  >
                    {project["desc"]
                      .replace("🏆", "")
                      .replace("⚡", "")
                      .replace("⭐", "")
                      .replace(".", "")}
                  </span>
                </Typography>
            </div>
            </a>
           

            </>
          }
        />
      </ListItem>
    );
  };


  return (
        <>
           
          <div className={classes.root}>
          {props.match.params.quote !== 'about' ?
            <>
            {commentDialog}
            {filterMenu}
            {trendingTopFilterMenu}
            {loginMenu}
           
            <Tester
            nav = {
             
              <>
                <IconButton onClick={() => {history.push('/')}} style={{padding: 0, marginLeft: 2}}>
                  <img src={logo} height={40} width={40}/> 
                 </IconButton>
                 <div/>
                
                 <IconButton  disabled={props.match.params.quote != undefined}   style={(filter == 'week' || filter == 'all') ? {color: props.match.params.quote == undefined ? 'black' : 'grey',padding: 8, marginTop: 16, marginRight: 8, borderRadius: 50}  : {color: props.match.params.quote == undefined ? 'black' : 'grey',padding: 8, marginTop: 16, marginRight: 8, borderRadius: 50}} onClick={handleClickTop} >
              <i style={{fontSize: 24}} class="ri-filter-line"></i>
              <Hidden smDown>
              <span style={{fontFamily: 'Open Sans', marginLeft: 16, marginRight: 8, fontSize: 18, fontWeight: 700, color: props.match.params.quote == undefined ? 'black' : 'grey',}}>Filter</span>
              </Hidden>
              </IconButton>
              <div/>

                {/* <IconButton disabled={props.match.params.quote != undefined} onClick={handleClick} style={filter != 'week' && filter != 'search' && filter != 'all' ? {backgroundColor: '#f7f7f7', color: props.match.params.quote == undefined ? 'black' : 'grey',padding: "8px", marginTop: 16, borderRadius: 50} : {color: props.match.params.quote == undefined ? 'black' : 'grey',padding: "8px", marginTop: 16, borderRadius: 50}}>
                  <i style={{color: props.match.params.quote == undefined ? 'black' : 'grey', fontSize: 24}} class="ri-trophy-line"></i>
                  <Hidden smDown>
                  <span style={{marginLeft: 16, marginRight: 8, fontSize: 18, fontWeight: 700, color: props.match.params.quote == undefined ? 'black' : 'grey',fontFamily: 'Open Sans',}}>Awards</span>
                  </Hidden>
                </IconButton> */}
                <div/>

                {/* <IconButton disabled={props.match.params.quote != undefined} onClick={() => {
                  history.push('/chat')
                }} style={filter != 'week' && filter != 'search' && filter != 'all' ? {backgroundColor: '#f7f7f7', color: props.match.params.quote == undefined ? 'black' : 'grey',padding: "8px", marginTop: 16, borderRadius: 50} : {color: props.match.params.quote == undefined ? 'black' : 'grey',padding: "8px", marginTop: 16, borderRadius: 50}}>
                  <i style={{color: props.match.params.quote == undefined ? 'black' : 'grey', fontSize: 24}} class="ri-chat-3-line"></i>
                  <Hidden smDown>
                  <span style={{marginLeft: 16, marginRight: 8, fontSize: 18, fontWeight: 600, color: props.match.params.quote == undefined ? 'black' : 'grey',}}>Chat</span>
                  </Hidden>
                </IconButton>
                <div/> */}

             

             
                  <IconButton disabled={props.match.params.quote != undefined} onClick={(e) => {
                    if(filter != 'search'){
                      setFilter('search')
                      filterThisWeek(e, true)
                    } else {
                      setFilter('week')
                      searchInput.current.value = ""
                      setSearching(false)
                      setSearchList([])
                    }
                
                    }} style={filter != 'search' ? {padding: "8px", marginTop: 16, borderRadius: 50, color: props.match.params.quote == undefined ? 'black' : 'grey',} : {backgroundColor: '#f7f7f7', padding: "8px", marginTop: 16, borderRadius: 50, color: props.match.params.quote == undefined ? 'black' : 'grey',}}>

<i style={{color: props.match.params.quote == undefined ? 'black' : 'grey', fontSize: 24}} class="ri-search-line"></i>      
                    <Hidden smDown>
                    <span style={{marginLeft: 16, marginRight: 8, fontSize: 18, fontWeight: 700, color: props.match.params.quote == undefined ? 'black' : 'grey',fontFamily: 'Open Sans',}}>Search</span>
                    </Hidden>
                  </IconButton>
                  <div/>

                  {/* <IconButton  onClick={() => {
                  history.push('/blog')
                }} style={{padding: "8px", marginTop: 16, borderRadius: 50}}>
                  <i style={{color: 'black', fontSize: 26}} class="ri-quill-pen-line"></i>
                  <Hidden smDown>
                  <span style={{marginLeft: 16, marginRight: 8, fontSize: 18, fontWeight: 700, color: 'black', fontFamily: 'Open Sans',}}>Blog</span>
                  </Hidden>
                </IconButton> */}
                {/* <div/> */}


                <IconButton  onClick={() => {
                  window.open("https://httboqu3t5a.typeform.com/to/nmt9wTHk");
                }} style={{padding: "8px", marginTop: 16, borderRadius: 50}}>
                  <i style={{color: 'black', fontSize: 26}} class="ri-add-line"></i>
                  <Hidden smDown>
                  <span style={{marginLeft: 13, marginRight: 8, fontSize: 18, fontWeight: 700, color: 'black', fontFamily: 'Open Sans',}}>Add project</span>
                  </Hidden>
                </IconButton>
                <div/>
                <Hidden mdUp>
                <IconButton  onClick={() => {
                 history.push('/about')
                }} style={{padding: "8px", marginTop: 16, borderRadius: 50}}>
                  <i style={{color: 'black', fontSize: 26}}class="ri-more-fill"></i>
                  <Hidden smDown>
                  <span style={{marginLeft: 13, marginRight: 8, fontSize: 18, fontWeight: 700, color: 'black', fontFamily: 'Open Sans',}}>More</span>
                  </Hidden>
                </IconButton>
                </Hidden>
                <div/>
               
               
               
                <IconButton   onClick={handleClickLoginEvent}  style={{marginLeft: 2, marginTop: 16, padding: user == null ? "8px" : "2px", borderRadius: 50}}>
                {user !== null ?
                <>
                    <Avatar
                  alt={user['displayName']}
                  style={{
                    marginRight: 0,
                  }}
                  src={
                    user['providerData'][0]['photoURL'].replace('normal', '400x400')
                  }
              
                />
                    <Hidden smDown>
                    <Typography style={{textAlign: 'left', marginLeft: 8, marginRight: 16, fontWeight: 800, color: 'black', fontFamily: 'Open Sans', fontSize: 14}}>
                      Profile
                      <div style={{color: 'grey', fontSize: 12, fontWeight: 400, marginTop: -3}}>{user['displayName']}</div>
                    </Typography>
                    </Hidden>
                  </>
                : <><i style={{color: 'black', fontSize: 24}}  class="ri-admin-line"></i>
                 <Hidden smDown>
                <span style={{marginLeft: 14, marginRight: 8, fontSize: 18, fontWeight: 600, color: 'black'}}>Login</span>
                </Hidden></>}
                </IconButton>
                <div/>
               </>
              
            }
            main = {
              <div>
              <CssBaseline />
             
                <Toolbar disableGutters={true} position="absolute">
              

                { props.match.params.quote == undefined ?
                filter !== 'search' ?

                <>
                {filter != 'week' ?
                  <>
                <IconButton style={{marginLeft: 16, color: 'black', padding: 8}} onClick={(e) => {
                  filterThisWeek(e, false)
                  setFilter('week')
                  }} >
                <i style={{fontSize: 24}} class="ri-arrow-left-line"></i>
                </IconButton>
                </>
                : ""}
                <Typography style={{fontFamily: 'Open Sans', margin: '4px 0px 0px 16px',fontWeight: 900, color: 'black', fontSize: 18}}>
                  {filter === 'week' ? 'Trending projects' : filter === "all" ? 'Top projects' : filter === 'star' ? 'Recommended' : filter === "top" ? "Best description" : filter === 'pow' ? 'Project of the week' : 'Trending projects'}
              
                  <Typography style={{fontFamily: 'Open Sans', margin: '0px 0px 4px 0px', color: 'grey', fontSize: 14}}>
                    {filter === 'week' ? 'The hot new Indie Hacker side projects this week' : filter === "all" ? 'The all-time best projects on Fiddy' : filter === 'star' ? 'Awesome projects I recommend' : filter === "top" ? "Projects with the best descriptions in the fewest characters" : filter === 'pow' ? 'Every week, I choose my favorite project' : 'The hot new Indie Hacker side projects this week'}
                  </Typography>
              </Typography>
             

              </> 
              
              : 
              <>
            

              
              <div style={{flexGrow: 1, marginLeft: 10, marginRight: 10, borderRadius: 4, backgroundColor: '#f7f7f7', padding: "4px 8px 4px 8px"}}>
              <Grid alignItems='center' container direction='row' justify="space-between">
                  <Grid item >
                    <IconButton disabled style={{padding: "0px 10px 1px 8px"}}>
                      <i style={{color: 'grey', fontSize: 20}}  class="ri-search-line"></i>
                    </IconButton>
                  </Grid>

                  <Grid item xs={9} lg={10}>
                    <Input
                      inputRef={searchInput}
                      onChange={handleSearch}
                      fullWidth
                      style={{
                        marginTop: 0,
                        fontSize: 16,
                        backgroundColor: "#f7f7f7",
                        fontFamily: "Open Sans",
                        padding: 0,
                       
                      }}
                      className={classes.input}
                      placeholder=" Search project name, description, link"
                    />
                  </Grid>

                  <Grid item>
                    <IconButton onClick={() =>   {
                          if(searchInput.current.value.length < 1) {
                            setFilter("week")
                          } else {
                            searchInput.current.value = ""
                          }
                          setSearching(false)
                          setSearchList([])
                        }} style={{color: 'grey', margin: "0px 2px 1px 5px", padding: 1}}>
                          
                                <i style={{color: '#cccccc', fontSize: 24}}  class="ri-close-circle-fill"/>
                                
                    </IconButton>
                  </Grid>
              </Grid>
            
          
        
        
            
            </div>
            </>
            
              
              :  <><IconButton style={{color: 'black', padding: 8, marginLeft: 16}} onClick={() => {
                history.push('/')
                setFilter('week')
                }} >
              <i style={{fontSize: 24}} class="ri-arrow-left-line"></i>
              </IconButton></>}
              
          

              
             
                </Toolbar>
                <Divider/>
                <div style={{ marginTop: 0 }} />

                {loadedProjects.length > 0 && props.match.params.quote == undefined && !busySearching && !loading  ? (
                  <Virtuoso
                    totalCount={searching ? searchList.length : loadedProjects.length}
                    overscan={200}
                    item={GenProjectItem}
                    style={{ padding: 0, height: "2000px", width: "100%" }}
                    ref={virtuosoMain}
                   

                  />
                ) : (
                  currentProject != null ?
                  <> 
                  {GenCurrentProject()}
                  <Toolbar>
                  <Typography style={{fontFamily: 'Open Sans', fontSize: 14, fontWeight: 900, padding: 0}}>
                    {currentProject['comments'] != undefined ? currentProject['comments'].length : 0} <span style={{fontWeight: 400, marginLeft: 4}}>Comment{currentProject['comments'] != undefined ? (currentProject['comments'].length == 1 ? "" : "s") : "s"}</span>
                  </Typography>
                  <Typography style={{flexGrow: 1, fontFamily: 'Open Sans', fontSize: 14, fontWeight: 900, padding: 0, marginLeft: 16}}>
                    {currentProject['votes'] + currentProject['score']} <span style={{fontWeight: 400, marginLeft: 4}}>Like{currentProject['votes'] + currentProject['score'] == 1 ? "" : "s"}</span>
                  </Typography>
                  <IconButton style={{padding: 8, marginRight: 8}} onClick={(e) => { 
                      if (isLoggedIn) {
                            
                            setCommentParent(currentProject)
                            handleClickOpen() 
                            
                          
                            } else {
                            setAnchorElLoginEvent(e.target)
                            setReason('Login to comment on a project')
                            }}}>
                  <i style={{color: 'grey', fontSize: 24}} class="ri-chat-1-line"></i>
                  </IconButton>
                  <IconButton onClick={(e) => {
                        if (isLoggedIn) {
                          if (currentProject["voters"].includes(user.uid)) {
                            unvoteProject(currentProject["id"]);
                          } else {
                            upvoteProject(currentProject["id"]);
                          }
                        } else {
                          setAnchorElLoginEvent(e.target);
                          setReason('Login to like a project')
                        }
                      }} style={{padding: 8}}>
                    <i style={{color: isLoggedIn
                          ? currentProject["voters"].includes(user.uid)
                            ? "#e0245e"
                            : "grey"
                          : "grey", fontSize: 24}}  class={"ri-heart-3-" + (isLoggedIn ? currentProject["voters"].includes(user.uid) ? "fill" : "line" : "line")}></i>
                  </IconButton>

                  
                  </Toolbar>
                

                  <Divider/>
                  { currentProject['comments'] != undefined ? (
                  currentProject['comments'].length === 0 ? 
                  <div style={{width: '100%'}} >
                  <Typography style={{padding: 64, color: 'grey', alignContent: 'center'}}>
                    No comments yet. Go ahead and start the discussion.
                  </Typography>
                  </div>
                  :
                  <Virtuoso
                    totalCount={currentProject['comments'].length}
                    overscan={5}
                    style={{ padding: 0, height: currentProject['comments'].length * 200, width: "100%"}}
                            
                    item={(index) => {
                      let comment = currentProject['comments'][index]
                      let isFirst = comment['depth'] == 0
                      var ONE_SEC = 5000
                      var checkDate = new Date() - comment['datetime'].toDate() < 5000
                     
                      
                    
                     
                     
                      return showComments.includes(comment['reply_to']) ? <div className={classes.hoverLight} style={{backgroundColor: checkDate ? "#fafafa" : 'transparent'}}>
                      <ListItem divider button onClick={() => {
                        if(!showComments.includes(comment['id'])) {
                          setShowComments([...showComments, comment['id']])
                        } else {
                          let temp = showComments
                          var i;
                          for( i = index ; i <  currentProject['comments'].length ; i++) {
                            let c = currentProject['comments'][i]
                            if(c['depth'] == comment['depth'] && c['id'] != comment['id']) {
                              break
                            }
                            temp = temp.filter(e => e != c['id'])
                          }
                         
                          setShowComments(temp)
                        }
                      }} style={{padding: "16px 16px 0px 0px", paddingLeft:  isFirst ? 16 : ((comment['depth'] + 1) * 16)}}   alignItems="flex-start">
                     

                      
                        <ListItemAvatar>
                        <Avatar
                            alt={comment['displayName']}
                            style={{
                              marginRight: 16,
                              backgroundColor: "#ffc700",
                              width: 40,
                              height: 40
                            }}
                            src={
                              comment['photoURL']
                            }
                          /> 
                        </ListItemAvatar>
                        
                        <ListItemText 
                        primary={
                        <div style={{fontWeight: 900, fontFamily: 'Open Sans', fontSize: 16}}>
                          {comment['username']} 
                          <span style={{fontWeight: 400, color: '#aaaaaa', fontSize: 14, fontFamily: 'Open Sans'}}> · <ReactTimeAgo date={comment['datetime'].toDate()} timeStyle="twitter"/> </span>
                        </div>} 
                        secondary={
                          <>
                        <div>
                          
                            {!isFirst ?
                            <>
                            <span style={{marginRight: 4, color: 'grey', fontFamlily: 'Open Sans', fontSize: 14, backgroundColor: "#e9e9e9", padding: "3px 6px", borderRadius:100}}>{comment['reply_to_username']}</span>  
                            <Twemoji style={{fontFamily: 'Open Sans', fontSize: 16, lineBreak: 'break-word'}} svg text={comment['content']}/>
                            </>  :  <Twemoji style={{fontFamily: 'Open Sans', fontSize: 16}} svg text={comment['content']}/>
                        }
                        </div>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          justify="space-between"
                          style={{marginTop: 2}}
                        >
          
                          <Grid item>
                            <IconButton onClick={(e) => {
                              if (isLoggedIn) {
                            
                              setCommentParent(comment)
                              handleClickOpen() 
                              
                            
                              } else {
                              setAnchorElLoginEvent(e.target)
                              setReason('Login to comment on a project')
                              }
                            
                          }} style={{padding: 4, marginLeft: -4, marginBottom: 8, marginRight: 2, marginTop: 8}}>
                              <i style={{color: 'grey', fontSize: 20}} class="ri-chat-1-line"></i>
                            </IconButton>
                            <Typography variant="span" style={{color: 'grey', fontSize: 12, fontFamily: 'Open Sans'}}>
                              {comment['nr_comments']}
                            </Typography>
                          </Grid>
          
                        </Grid>
                        </>
                      }/>
                          
                      </ListItem>
                  
                      </div>
                    : "" } 
                  }

                  />
                  ) :    <div
                  style={{ marginTop: 32, padding: 32, width: "20%", margin: "0 auto" }}
                >
                  <CircularProgress thickness={6.0} size={18}/>
                </div> }
                   
                  </>
                  :
                  <div
                    style={{ marginTop: 32, padding: 32, width: "20%", margin: "0 auto" }}
                  >
                    <CircularProgress thickness={6.0} size={18} color="#ffc700"/>
                  </div>
                )}
              </div>
            }

            extra = {

            
             <>
                {aboutSection()}
           </>
            }/>

            <Hidden smUp>
              <Divider/>

<Toolbar>
  <>
  <Grid
    container
    direction="row"
    justify={'space-between'}
    alignItems="center"
  >
    {/* <Grid item>
    <IconButton onClick={() => {history.push('/')}} style={{padding: 8}}>
      <img src={logo} height={40} width={40}/> 
    </IconButton>
    </Grid> */}


    <Grid item>
    <IconButton  disabled={props.match.params.quote != undefined}   style={(filter == 'week' || filter == 'all') ? {backgroundColor: "#f7f7f7", color: props.match.params.quote == undefined ? 'black' : 'grey',padding: 8}  : {color: props.match.params.quote == undefined ? 'black' : 'grey',padding: 8}} onClick={handleClickTop} >
              <i style={{fontSize: 24}}  class="ri-filter-line"></i>
              <Hidden smDown>
              <span style={{fontFamily: 'Open Sans',marginLeft: 16, marginRight: 8, fontSize: 18, fontWeight: 600, color: props.match.params.quote == undefined ? 'black' : 'grey',}}>Projects</span>
              </Hidden>
              </IconButton>
    </Grid>

    {/* <Grid item>
    <IconButton disabled={props.match.params.quote != undefined} onClick={handleClick} style={filter != 'week' && filter != 'search' ? {backgroundColor: '#f7f7f7', color: props.match.params.quote == undefined ? 'black' : 'grey',padding: "8px"} : {color: props.match.params.quote == undefined ? 'black' : 'grey',padding: "8px"}}>
                  <i style={{color: props.match.params.quote == undefined ? 'black' : 'grey', fontSize: 24}} class="ri-trophy-line"></i>
                  <Hidden smDown>
                  <span style={{fontFamily: 'Open Sans',marginLeft: 16, marginRight: 8,fontFamily: 'Open Sans', fontSize: 18, fontWeight: 600, color: props.match.params.quote == undefined ? 'black' : 'grey',fontFamily: 'Open Sans',}}>Awards</span>
                  </Hidden>
                </IconButton>
    </Grid> */}


    <Grid item>
    <IconButton disabled={props.match.params.quote != undefined} onClick={(e) => {
                    if(filter != 'search'){
                      setFilter('search')
                      filterThisWeek(e, true)
                    } else {
                      setFilter('week')
                      searchInput.current.value = ""
                      setSearching(false)
                      setSearchList([])
                    }
                
                    }} style={filter != 'search' ? {padding: "8px", color: props.match.params.quote == undefined ? 'black' : 'grey',} : {backgroundColor: '#f7f7f7', padding: "8px", color: props.match.params.quote == undefined ? 'black' : 'grey',}}>

                    <i style={{color: props.match.params.quote == undefined ? 'black' : 'grey', fontSize: 24}} class="ri-search-line"></i>   
                    <Hidden smDown>
                    <span style={{marginLeft: 16, marginRight: 8, fontSize: 18, fontWeight: 600, color: props.match.params.quote == undefined ? 'black' : 'grey',fontFamily: 'Open Sans'}}>Search</span>
                    </Hidden>
                  </IconButton>
    </Grid>
   
   <Grid item>
   <IconButton  onClick={() => {
      window.open("https://httboqu3t5a.typeform.com/to/nmt9wTHk");
    }} style={{padding: 8}}>
      <i style={{color: 'black', fontSize: 26}} class="ri-add-line"></i>
    </IconButton>
   </Grid>

  <Grid item>
    <IconButton className={classes.btn} onClick={handleClickLoginEvent} style={{padding: user == null ? 8 : 0, marginRight: 16}}>
          {user !== null ?
            <Avatar
            alt={user['displayName']}
            style={{
              marginRight: 0,
            }}
            src={
              user['providerData'][0]['photoURL'].replace('normal', '400x400')
            }
          />
          : <i style={{color: 'black', fontSize: 24}}  class="ri-admin-line"></i>}
      </IconButton>
   </Grid>

   </Grid>

   </>
</Toolbar>
      </Hidden>
<Dialog
  className={classes.dialog}
  style={{ borderRadius: 0 }}
  open={openLogin}
  anchorEl={anchorElLogin}
  onClose={handleClosePopoverLogin}
  maxWidth={"xs"}
  elevation={5}
>
  <div style={{ padding: 42 }}>
    <Typography
      style={{
        fontSize: 20,
        marginBottom: 8,
        fontFamily: "Open Sans",
        color: "black",
        fontWeight: 900,
      }}
    >
      Welcome to Fiddy {"  "} <Twemoji svg text="👋" />
    </Typography>
    <Typography
      style={{
        fontSize: 16,
        marginBottom: 16,
        fontFamily: "Open Sans",
        color: "#aaaaaa",
      }}
    >
      Sign in to upvote the projects you like or to leave a comment 
    </Typography>
    <Button
      className={classes.signInButtonTwitter}
      onClick={() => {
        signInUserTwitter();
      }}
    >
      Sign in with Twitter
    </Button>
    <div />
    <Button
      className={classes.signInButtonGoogle}
      onClick={() => {
        signInUserGoogle();
      }}
    >
      Sign in with Google
    </Button>
  </div>
</Dialog>

         </> : <div style={{padding: 16}}>{aboutSection()} </div> }

          </div> 
        </>
  );
}
