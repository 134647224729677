import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyArR6ixUSXBSb1O2tpWmcCu88Dad9DMR-Y",
    authDomain: "fiftychar.firebaseapp.com",
    databaseURL: "https://fiftychar.firebaseio.com",
    projectId: "fiftychar",
    storageBucket: "fiftychar.appspot.com",
    messagingSenderId: "650780876239",
    appId: "1:650780876239:web:5422e8cc5af717a02c86ba",
    measurementId: "G-2SDHKDW7DH"
  };



export const myFirebase = firebase.initializeApp(firebaseConfig);

export var twitterProvider = new firebase.auth.TwitterAuthProvider();
export var googleProvider = new firebase.auth.GoogleAuthProvider();

export const db = myFirebase.firestore();

export const Timestamp = firebase.firestore.Timestamp

export const fieldValue = firebase.firestore.FieldValue;

export const auth =  firebase.auth();
