import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Twemoji } from 'react-emoji-render';
import { Typography } from '@material-ui/core';
import { useHistory } from "react-router-dom";



const useStyles = makeStyles((theme) => ({
  
}));

export default function Blog(props) {
  const classes = useStyles();
  const history = useHistory();


  return (
    <> 
    <List style={{padding: '8px 0px 0px 16px'}}>
     <IconButton onClick={() => {
            history.push('/')
          }} style={{padding: 8, borderRadius: 25}}>
            <i style={{color: 'black', fontSize: 24}}  class="ri-arrow-left-line"></i>
            <Typography style={{fontFamily: 'Open Sans', color: 'black', fontWeight: 600, marginLeft: 16, marginRight: 8}}>
                Back to projects
            </Typography>
          </IconButton>
   </List>       
    <Typography style={{padding: "32px 0px 0px 16px ", fontFamily: 'Open Sans'}}>
        <b style={{borderRadius: 4, backgroundColor: "#ffc700", padding: '4px 8px', marginBottom: 4}}>Stay tuned <Twemoji svg text=" 📣" /> </b> <br/>
    </Typography>
    <Typography style={{padding: "16px 0px 0px 16px", fontFamily: 'Open Sans'}}>
       <span style={{marginTop: 8}}> The Fiddy Blog is coming soon.</span>
    </Typography>
    <img style={{padding: '16px 0px 0px 16px'}}  width={240*1.3} height={199*1.3} src="https://media.giphy.com/media/13CoXDiaCcCoyk/giphy.gif" />
    </>
  );
}
