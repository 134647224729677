import React from 'react';
import ReactDOM from 'react-dom';
import Home from './containers/Home'
import Blog from './containers/Blog'
import * as serviceWorker from './serviceWorker';

import { BrowserRouter as Router } from "react-router-dom";
import { Route, Switch } from "react-router-dom";

import "./styles.css";



ReactDOM.render(
  <React.StrictMode>
      <Router>
        <Switch>
        {/* <Route exact path="/chat" component={Chat} /> */}
        <Route exact path="/blog/:title" component={Blog} />
        <Route exact path="/blog" component={Blog} />
        <Route exact path="/:quote" component={Home} />
        <Route exact path="/" component={Home} />
        </Switch>
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
