import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Twemoji } from 'react-emoji-render';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: 'transparent',
    fontFamily: 'Open Sans'
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function About() {
  const classes = useStyles();

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      
      <ListItem alignItems="flex-start" style={{backgroundColor: 'transparent'}} >
      <ListItemIcon>
            <IconButton disabled style={{backgroundColor: '#f1f1f1', padding: 8, borderRadius: 100}}>
              <i style={{fontSize: 18, color: 'grey'}} class="ri-code-s-slash-line"></i>
              </IconButton>
            </ListItemIcon>
      <ListItemText primary={<b  style={{fontFamily: 'Open Sans'}}>How projects are ranked</b>}  secondary={<span  style={{fontFamily: 'Open Sans'}}>The higher your project ranks every week, the more exposure it will get. Think Product Hunt, but you have a chance to be #1 every week.</span>} />
      </ListItem>

      <List component="div" disablePadding>
          <ListItem alignItems="flex-start" className={classes.nested}>
            
            <ListItemIcon>
            <IconButton disabled style={{backgroundColor: '#f1f1f1', padding: 8, borderRadius: 100}}>
              <i style={{fontSize: 18, color: 'grey'}} class="ri-fire-line"></i>
              </IconButton>
            </ListItemIcon>
          
            <ListItemText primary={<b  style={{fontFamily: 'Open Sans'}}>Trending</b>}  secondary={<span  style={{fontFamily: 'Open Sans'}}>The hot projects with the most likes this week. Trending resets every week on Sunday at 7pm (UTC).</span>} />
          </ListItem>
         
          <ListItem alignItems="flex-start" className={classes.nested}>
          <ListItemIcon>
            <IconButton disabled style={{backgroundColor: '#f1f1f1', padding: 8, borderRadius: 100}}>
              <i style={{fontSize: 18, color: 'grey'}}  class="ri-medal-line"></i>
              </IconButton>
            </ListItemIcon>
            <ListItemText primary={<b  style={{fontFamily: 'Open Sans'}}>Top</b>}  secondary={<span  style={{fontFamily: 'Open Sans'}}>The all-time best projects on Fiddy.</span>} />
          </ListItem>
          
          <ListItem alignItems="flex-start" className={classes.nested}>
          <ListItemIcon>
            <IconButton disabled style={{backgroundColor: '#f1f1f1', padding: 8, borderRadius: 100}}>
              <i style={{fontSize: 18, color: 'grey'}}  class="ri-arrow-up-fill"></i>
              </IconButton>
            </ListItemIcon>
            <ListItemText primary={<b  style={{fontFamily: 'Open Sans'}}>Promoted projects</b>} secondary={<span  style={{fontFamily: 'Open Sans'}}>Promoted projects always appear at the top of the list. At the end of the week, the top Trending project will be promoted for a week. </span>} />
          </ListItem>
        </List>

      
      {/* <ListItem alignItems="flex-start" style={{backgroundColor: 'transparent'}} disableRipple >
        <ListItemIcon>
        <IconButton disabled style={{backgroundColor: '#f1f1f1', padding: 8, borderRadius: 100}}>
        <i style={{fontSize: 18, color: 'grey'}}  class="ri-trophy-line"></i>
              </IconButton>
        </ListItemIcon>
      <ListItemText primary={<b  style={{fontFamily: 'Open Sans'}}>Awards</b>}  secondary={<span  style={{fontFamily: 'Open Sans'}}>Every week, I give awards to exceptional new projects.</span>} />
      </ListItem>
        
        <List component="div" disablePadding>
          <ListItem alignItems="flex-start" className={classes.nested}>
            <ListItemIcon>
            <IconButton disabled  style={{
                    marginRight: 8,
                    fontSize: 16,
                    marginBottom: 8,
                    marginTop: 8,
                    padding: "8px 8px 8px 8px",
                    borderRadius: 100,
                    backgroundColor: "#f1f1f1",
                  }}>
                <Twemoji svg text="⭐" />
              </IconButton>
            </ListItemIcon>
            <ListItemText primary={<b  style={{fontFamily: 'Open Sans'}}>Recommended</b>}  secondary={<span  style={{fontFamily: 'Open Sans'}}>Awesome projects I recommend.</span>} />
          </ListItem>
          <ListItem alignItems="flex-start" className={classes.nested}>
            <ListItemIcon>
            <IconButton disabled  style={{
                    marginRight: 8,
                    fontSize: 16,
                    marginBottom: 8,
                    marginTop: 8,
                    padding: "8px 8px 8px 8px",
                    borderRadius: 100,
                    backgroundColor: "#f1f1f1",
                  }}>
                <Twemoji svg text="👏" />
              </IconButton>
            </ListItemIcon>
            <ListItemText primary={<b  style={{fontFamily: 'Open Sans'}}>Best description</b>}  secondary={<span  style={{fontFamily: 'Open Sans'}}>These projects have the best descriptions in the fewest characters.</span>} />
          </ListItem>
          <ListItem alignItems="flex-start" className={classes.nested}>
            <ListItemIcon>
            <IconButton disabled   style={{
                    marginRight: 8,
                    fontSize: 16,
                    marginBottom: 8,
                    marginTop: 8,
                    padding: "8px 8px 8px 8px",
                    borderRadius: 100,
                    backgroundColor: "#f1f1f1",
                  }}>
                <Twemoji svg text="🏆" />
              </IconButton>
            </ListItemIcon>
            <ListItemText primary={<b  style={{fontFamily: 'Open Sans'}}>The project of the week</b>} secondary={<span  style={{fontFamily: 'Open Sans'}}>Every week, I choose my favorite project.</span>} />
          </ListItem> */}
        {/* </List> */}
    </List>
  );
}
